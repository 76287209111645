import React, { useState } from "react";

interface AccordionItemProps {
    title: string;
    content: string;
    readMore: boolean;
  }

const  AccordionItem: React.FC<AccordionItemProps> = ({ title, content,readMore }) => {
  const [isOpen, setIsOpen] = useState(false);

  const contentWithReadMore = readMore 
  ? content + '<p><a href="#" class="read-more-link">Read More</a></p>': content;

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
   
  return (
    <div className="accordion-item">
      <div className="accordion-header accordion-header-toggle" onClick={toggleAccordion}>
        <span className="accordion-title">{title}</span>
        <img
          src={isOpen ? "/content/img/Collapse.png" : "/content/img/Expand.png"}
          alt={isOpen ? "Collapse" : "Expand"}
          className="accordion-icon"
        />
      </div>
      {isOpen && 
        <div className="accordion-content toggle-content" dangerouslySetInnerHTML={{ __html: contentWithReadMore }} ></div>}
      
    </div>
  );
};

export default AccordionItem;