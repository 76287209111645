import TogglePanel from "../../components/TogglePanel/TooglePanel";
import News from "../../json/newspage.json";
import MetaTag from "../../components/MetaTag/MetaTag";
import SubFooter from "../../components/SubFooter/SubFooter";

const NewsData = News.accordionSection;
const news: React.FC = () => {
    return(
        <>
            <MetaTag title={ "BCE GlobalTech - News" } description={ "" }/>
            <div className="container liquid-container pad-mobile">
                <div className="spacer20 clear hidden-xs hidden-sm"></div>
                <h1 className="news-title">{News.title}</h1>
                <p className="news-description">{News.description}</p>
                <div className="spacer40 clear hidden-xs hidden-sm"></div>
                <div className="spacer30 hidden-lg hidden-md"></div>
                <div className="accordion-container">
                    {NewsData.map((item, index) => (
                    <TogglePanel key={index} title={item.title} content={item.content} readMore={item.readMore}/>
                    ))}
                    <div className="spacer30"></div>
                    <a className="icon more-link txtBold txtUnderline" 
                        href="/news-archives">News Archives</a>
                </div>
            </div>

            <SubFooter
                title="Need more information?"
                buttonText="Contact us"
                buttonUrl="/contactus"
                isCustomInfo={true}
                moreInformationText="Connect with us today to learn more about our services and solutions"
                moreInformationMailToId="Email: info@bceglobaltech.com"
            />
            
        </>
    )
}
export default news