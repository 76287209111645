import React,{useEffect,useState} from "react";


interface StepperContent{
    bannerImage?:string,
    bannerImageMobile?:string,
    bannerImageTab?:string,
    bannerImage1?:string,
    bannerImageMobile1?:string,
    bannerImageTab1?:string,
    isBanner:boolean,
    title:string,
    content:string,
    stepper:string,
    isShowButton:boolean,

}
interface StepperProps{
    isBanner:boolean,
    stepperContent:StepperContent[]
}
let stepperValue = 0;
let twinImageTimer:any;
const Stepper = ({isBanner,stepperContent}:StepperProps) => {   
    const [currentIndex,setCurrentIndex] = useState(0) 
    const [stepBanner,setStepBanner] = useState("");
    const [stepTitle,setStepTitle] = useState("");
    const [stepSubTitle,setStepSubTitle] = useState("");
    const [stepContent,setStepContent] = useState("");
    const screenWidth = window.screen.width;

    const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    const img = new Image();
    img.src = `url(${stepBanner})`;
    img.onload = handleImageLoad;
  }, []);


    useEffect(() =>{        
        if(isBanner && currentIndex == 0){
            const currentBannerSize = screenWidth <= 540 ? stepperContent[0].bannerImageMobile 
            : screenWidth <= 968 ? stepperContent[0].bannerImageTab : stepperContent[0].bannerImage;
            setStepBanner(currentBannerSize || '');
            setStepTitle(stepperContent[0].title);
            setStepSubTitle(stepperContent[0].stepper);
            setStepContent(stepperContent[0].content); 
            //load the second image
            twinImageTimer = setTimeout(() => {loadTwinImage(0)},3000);            
        }  
        const interval = setInterval(() => {autoRotateStep()},6000);
        return () => clearInterval(interval);             
    },[stepperContent.length]);

    const autoRotateStep = () => {   
       const stepperIndex = (stepperValue + 1) % stepperContent.length;        
       stepClick(stepperIndex);
       console.log("CurrentIndex ",stepperIndex);       
    };
    const loadTwinImage = (step:any) => {
        const currentBannerSize1 = screenWidth <= 540 ? stepperContent[step].bannerImageMobile1 
            : screenWidth <= 968 ? stepperContent[step].bannerImageTab1 : stepperContent[step].bannerImage1;
            setStepBanner(currentBannerSize1 || '');
    };

    const stepClick = (step:any) =>{
        stepperValue = step;
        setCurrentIndex(step);  
        if(twinImageTimer){
            clearTimeout(twinImageTimer);
        }             
        const currentBannerSize = screenWidth <= 540 ? stepperContent[step].bannerImageMobile 
            : screenWidth <= 968 ? stepperContent[step].bannerImageTab : stepperContent[step].bannerImage;
        setStepBanner(currentBannerSize || '');
        setStepTitle(stepperContent[step].title);
        setStepSubTitle(stepperContent[step].stepper);
        setStepContent(stepperContent[step].content);      
        //load the second image
        twinImageTimer = setTimeout(() => {loadTwinImage(step)},3000);           
    };
    const stepBtnClick = (btn:any) => {
        if(btn === 'back'){
            let step = 0;
            if(currentIndex == 0){
                step = stepperContent.length - 1;                
            }
            else {
                step = currentIndex - 1;
            }
            setStepBanner(stepperContent[step].bannerImage || '');
            setStepTitle(stepperContent[step].title);  
            setStepSubTitle(stepperContent[step].stepper);
            setStepContent(stepperContent[step].content); 
            setCurrentIndex(step);
        }else{
            let step = 0;
            if(stepperValue == stepperContent.length - 1){
                setCurrentIndex(0)
            }
            else{
                step = currentIndex + 1;
            }
            setStepBanner(stepperContent[step].bannerImage || '');
            setStepTitle(stepperContent[step].title);  
            setStepSubTitle(stepperContent[step].stepper);
            setStepContent(stepperContent[step].content); 
            setCurrentIndex(step);
        }                     
    };    
    return(
        <>
            <div className="">
                <div className="stepper-container">
                    {isBanner && (
                        <>
                            <div className="stepper-banner"  style={{
        backgroundImage: imageLoaded
          ? `url(${stepBanner})`
          : `url(${stepBanner})`,
        transition: 'background-image 0.5s ease-in-out', // Smooth transition
      }} >                 

      {/* style={{backgroundImage:`url(${stepBanner})` }}           */}
                                <div className="stepper-banner-content">
                                    <h6 className="txtSize16">{stepSubTitle}</h6>
                                    <h2 className="bellSlimBold">{stepTitle}</h2>
                                    <p className="txtSize16">{stepContent}</p>
                                </div>
                            </div>
                        </>                        
                    )}
                    {/* <div className="stepper-button hidden-xs">
                        <a className="stepper-btn stepper-btn-left" onClick={(e) => (stepBtnClick('back'))} role="button"><GoChevronLeft /></a>
                        <a className="stepper-btn stepper-btn-right" onClick={(e) => (stepBtnClick('next'))} role="button"><GoChevronRight /></a>
                    </div> */}
                </div>                      
                <div className="clear spacer20"></div>  
                <div className="stepper-box">  
                    <ul className="stepper-list">
                        {stepperContent.map((step,index) =>(
                            <li className="stepper-item" key={index}>
                                <a className={`stepper-item-dots 
                                    ${stepperValue == index ? 'stepper-item-active-dots':''}`} onClick={(e) => (stepClick(index))}>                                        
                                    <span>{step.stepper}</span>
                                </a>
                            </li>
                        ))}                                    
                    </ul>
                </div>  
            </div>
        </>
    );
};

export default Stepper;