import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Tab from '../../components/Layout/Tab/Tab';
import Banner from "../../components/Banner/Banner";
import Tile from "../../components/Tile/Tile";
import SubFooter from "../../components/SubFooter/SubFooter";
import CareerData from "../../json/careerpage.json";
import MetaTag from "../../components/MetaTag/MetaTag";
import Section from "../../components/Section/Section";
import SearchResult from "../../components/Search/SearchResult";
//import S3ClientService   from "../../service/S3Client";
import JobSpecification from "../../json/jobSpecification.json";
import Stepper from '../../components/Stepper/Stepper';
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

const careerBanner = CareerData.bannerSection;
const careerJobCategoryTileList = CareerData.jobTileSection;
const careerTileList = CareerData.offerTileSection;

interface KeyValue{
  value:string;
}
interface Job {
  jobId: string;
  jobTitle: string;
  jobDescription: string;
  location: string;
  jobCategory:string;
  jobType:string,
  workProfile:string,
  experience:string,  
  noOfOpenings:number,          
  keyResponsibility:KeyValue[],
  technologySkills:KeyValue[],
  requiredQualification:KeyValue[],
  workingConditions?:KeyValue[],
  ourDetails:string,
  ourOffer:KeyValue[],
  additionalInformation:KeyValue[],
  status:string,
  effectiveFrom:string,
  effectiveTo:string,
  createdOn: string,
  publishedOn: string,
  completedOn: string,
  holdOn: string,
  deletedOn: string
}

const CareerNew = () =>{
    const [currentTab,setCurrentTab] = useState("#tab1");
    const tabRoute = useLocation();
    const [jobSearchTab,setJobSearchTab] = useState(false);
    const [jobSpecification,setJobSpecification] = useState<Job[]>([]);    
    const [bannerSearchText, setBannerSearchText] = useState("");
    const [selectedFilters, setSelectedFilters] = useState<{
        category: Set<string>;
        jobType: Set<string>;
    }>({
        category: new Set(),
        jobType: new Set(),
    });

    useEffect(() => {        
        setCurrentTab(tabRoute.hash);
        if(tabRoute.hash.includes("#tab2?jobSearch=")){
            setCurrentTab("#tab2");
            setJobSearchTab(true);
            // S3ClientService().send((res,data) => {
            //     console.log("S3 Bucket",data);
            //     const jsonString = data.Body?.toString('utf-8');
            //     const jsonObject = JSON.parse(jsonString || '{}');  
            //     setJobSpecification(jsonObject.jobs.filter((job:any) => job.status === "Published"));
            // });   
            setJobSpecification(JobSpecification.jobs.filter((job:any) => job.status === "Published"));                   
        }else{
            setJobSearchTab(false);
        }
        if(tabRoute.hash === ''){
            setCurrentTab("#tab1");
        }        
    },[tabRoute.hash]);

    const handleBannerSearchText = (searchValue:any) =>{
        console.log("searchValue",searchValue);
    };

    const handleFiltersChange = (filters: { category: Set<string>; jobType: Set<string> }) => {
        setSelectedFilters(filters);
     };

    const tabs = CareerData.tabs;

    const steps = CareerData.steps;
    return(
        <>
            <MetaTag title={ CareerData.title } description={ CareerData.description }/>
            <Banner
                isCarousle={false}
                bannerCardPosition={"center"}
                bannerCardColor={""}
                bannerImages={careerBanner}
                stretchFullWidth={true}
                bannerClass={""} 
                bannerJobContentFlag={false}
                handleBannerSearchText={handleBannerSearchText}
                />
            <div className='clear spacer15'></div>    
            <Tab tabList={tabs}/>
            <div className='clear spacer15'></div>
            <div className='container liquid-container'>
                {/* Tab1 */}
                <div className={`tab-container ${currentTab === '#tab1' ? 'active_tab_container':''}`} id='#tab1'>
                    <div className="pad-15-xs">
                        <div className="container liquid-container">
                            <div className="spacer30 hidden-xs"></div>
                            <h2 className="bellSlimBold txtBlack txtCenter txtBold txtSize32 letterSpacing
					          txtSize26-xs margin-15-top">A better future, powered by you.</h2>
                            <div className="spacer30"></div>
                            <div className="inline-blck w-100">
                                <div className="bgGray19 left-blck w-30 w-sm-40 pad-30 borderradius w-100-xs contentBox-description-sm">
                                    <p className="txtBlack txtBold txtSize18">At BCE Global Tech, immerse yourself in exciting projects that are shaping the future of both consumer and enterprise telecommunications.</p>
                                    <p className="txtSize16">This involves building innovative mobile apps to enhance user experiences and enable seamless connectivity on-the-go.</p>
                                </div>
                                <div className="right-blck w-70 w-sm-60 pad-left-20-md pad-left-20-sm w-100-xs pad-top-20-xs">
                                    <p className="txtSize16">Our community of technology developers is shaping the future of the telecommunications industry. We offer next-generation products and services for consumers and small to medium businesses, including ultra-fast internet, immersive virtual reality experiences, innovative IoT solutions, and AI-enabled technologies for personalized user experiences and optimized network performance.</p>
                                    <p className="txtSize16">We foster a strong culture that nurtures career growth and encourages engagement in the entire technology lifecycle. Our agile teams collaborate on projects from ideation to deployment, fostering continuous learning in the pursuit of meticulously crafted projects. We lead, coach, and support through mentoring, and working in close-knit squads, guided by exceptional leaders.</p>
                                    <p className="txtSize16">Thrive in diverse roles like Full Stack Developer, Backend Developer, UI/UX Designer, DevOps Engineer, Cloud Engineer, Data Science Engineer, and Scrum Master at a workplace that encourages you to freely share your bold and different ideas.</p>

                                </div>
                            </div>
                        </div>
                          
                    </div>
                    <div className='clear spacer30'></div>
                    <div>
                        <Stepper isBanner={true} stepperContent={steps}/>
                    </div>  
                    <div className='clear spacer30'></div>
                    <SubFooter
                        title=""
                        buttonText="Contact us"
                        buttonUrl="/contactus"
                        isCustomInfo={true}
                        moreInformationText="For more information on the exciting opportunities with BCE Global Tech"         
                    />                    
                </div>
                {/* Tab2 */}
                <div className={`tab-container ${currentTab === '#tab2' ? 'active_tab_container':''}`} id='#tab2'>
                    <div className="clear  hidden-xs"></div>
                    <div className="pad-15-xs">  
                        {!jobSearchTab && (                      
                            <>    
                                <Tile
                                    blockHeaderTitle="Job categories"
                                    blockHeaderDescription=""
                                    tiles={careerJobCategoryTileList}
                                    customImageClass={"w-100"}
                                    customCardClass={"col-md-4 col-sm-4 width-100-percent-xs"}
                                    custombodyClass={"career-jobcategorytile"}
                                    customTitleClass={"bellSlimBold txtSize16 pad-top-12 underline-on-hover"}
                                    customFooterClass=""
                                    isBodyLinkTrue={false}
                                    isFooterLinkTrue={false}
                                    blockFooterButtons=""
                                    blockFooterText=""
                                    baseURL=""
                                    custombodyHeight={"min-h-57"} />  
                                <div className="clear spacer20 hidden-xs"></div>   
                                <Tile
                                    blockHeaderTitle="At BCE Global Tech we offer fulfilling and rewarding roles that include:"
                                    blockHeaderDescription=""
                                    tiles={careerTileList}
                                    customImageClass=""
                                    customCardClass={"col-md-3 col-sm-3 width-100-percent-xs"}
                                    custombodyClass={"bgGray19 pad-2-sm noBorder"}
                                    customTitleClass={"txtSize16 no-margin-sm mar-neg-top-10"}
                                    customFooterClass=""
                                    isBodyLinkTrue={false}
                                    isFooterLinkTrue={false}
                                    blockFooterButtons=""
                                    blockFooterText=""
                                    baseURL=""
                                    custombodyHeight={"h-170 h-220-sm"} />

                                <div className='clear spacer30'></div>
                                <SubFooter
                                    title=""
                                    buttonText="Contact us"
                                    buttonUrl="/contactus"
                                    isCustomInfo={true}
                                    moreInformationText="For more information on the exciting opportunities with BCE Global Tech"         
                                    />                        
                            </>
                        )}
                        {jobSearchTab && (
                            <>
                                <SearchResult onFiltersChange={handleFiltersChange} bannerSearchText={bannerSearchText} jobSpecifications={jobSpecification} />                     
                            </>
                        )}
                    </div>                    
                </div>
                {/* Tab3 */}
                <div className={`tab-container ${currentTab === '#tab3' ? 'active_tab_container':''}`} id='#tab3'>
                    <Section imageUrl={"/content/img/2-1632406552776.png"} 
                              imageLeft={true}
                              title={'Better World'}
                              description={'From support for mental health and protecting the environment, to accessibility and privacy, we are investing in society for a better tomorrow.'} />
                    <Section imageUrl={"/content/img/1-1632406551791.png"} 
                              imageLeft={false}
                              title={'Better communities'}
                              description={"​​​​​​​By investing and acting responsibly, we help build better communities across the country, and contribute to bettering Canada’s economy across all regions."} />
                    <Section imageUrl={"/content/img/AndreaNicho1-1632406762379.png"} 
                              imageLeft={true}
                              title={'Better workplace'}
                              description={"As one of largest employers, we support diversity, equity and inclusion in our workforce, as well as continuous learning, innovative workplace mental health initiatives, and award-winning programs for team member engagement."} />  
                </div>
            </div>            
        </>
    );
};

export default CareerNew;