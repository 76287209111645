import React , {useEffect} from "react";
import Banner from "../../components/Banner/Banner";
import SubFooter from "../../components/SubFooter/SubFooter";
import Tile from "../../components/Tile/Tile";
import CareerData from "../../json/blogPage.json";
import MetaTag from "../../components/MetaTag/MetaTag";


const careerJobCategoryTileList = CareerData.blogs;



const BlogList = () => {
  const handleBannerSearchText = (searchValue:any) =>{
    console.log("searchValue",searchValue);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto'
    });
  },[])
    return(
      <>
        {/* <MetaTag title={ CareerData.title } description={ CareerData.description }/> */}
        <MetaTag title={ "Blog" } description={ "All blog list is available" }/>
         
        <div className="bgWhite pad-15-xs">


        <div className="container liquid-container">
              <div className="spacer30 hidden-xs"></div>
              <h1 className="bellSlimBold txtBlack txtCenter txtBold txtSize22 letterSpacing
					          txtSize26-xs margin-15-top">Blogs</h1>
                 
              
          </div>

          <Tile
            blockHeaderTitle=""
            blockHeaderDescription=""
            tiles={careerJobCategoryTileList}
            customImageClass={"w-100"}
            customCardClass={"col-md-4 col-sm-6 width-100-percent-xs blog-card"}
            custombodyClass={"career-jobcategorytile txtSize14"}
            customTitleClass={"blog-title mar-top-0 txtBlack txtBold txtSize18"}
            customFooterClass=""
            isBodyLinkTrue={false}
            isFooterLinkTrue={false}
            blockFooterButtons=""
            blockFooterText=""
            baseURL=""
            custombodyHeight={"pad-30 txtLeft blog-title-height"}
            customCardLinksClass={"icon more-link txtBold margin-t-40 margin-t-20-xs"}
            customSubTitleClass={"blog-sub-title txtBlack txtSize12"}
             />
        <SubFooter
          title="Need more information?"
          buttonText="Contact us"
          buttonUrl="/contactus"
          isCustomInfo={true}
          moreInformationText="Connect with us today to learn more about our services and solutions"
          moreInformationMailToId="Email: info@bceglobaltech.com"
          />


        </div>          

        
          
      </>
     );
    
};

export default BlogList;