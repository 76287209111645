interface SectionProps{
    imageUrl?:string,
    title:string,
    description:string,
    imageLeft?:boolean
    isImageDisplay?:boolean
}
const Section = ({imageUrl,
title,
description,
imageLeft,
isImageDisplay}:SectionProps) => {    
    const screenWidth = window.screen.width;
    return(
        <>
           {(screenWidth > 968) &&
            <>
                <div className="section-container">
                    {(imageLeft) && 
                        <>
                            <div className='section-container-item section-container-image section-container-left'>
                                <img src={imageUrl}/>
                            </div>
                            <div className='section-container-item section-container-text section-container-right pad-30'>
                                <h2 className='bellSlimBold txtBlack'>{title}</h2>
                                <p className='txtBlack txtSize16'>{description}</p>
                            </div>
                    </> 
                    }                        
                    {(!imageLeft) && 
                        <>
                            <div className='section-container-item section-container-image section-container-left pad-30'>
                                <h2 className='bellSlimBold txtBlack'>{title}</h2>
                                <p className='txtBlack txtSize16'>{description}</p>                            
                            </div>
                            <div className='section-container-item section-container-text section-container-right'>
                                <img src={imageUrl}/>
                            </div>
                        </>
                    }
                </div>
            </>
            }
            {(screenWidth <= 968) && 
            <>
                <div className="section-container">
                    <div className='section-container-item section-container-image section-container-left'>
                        <img src={imageUrl}/>
                    </div>
                    <div className='section-container-item section-container-text section-container-right pad-30'>
                        <h2 className='bellSlimBold txtBlack'>{title}</h2>
                        <p className='txtBlack txtSize16'>{description}</p>
                    </div>
                </div>
            </>
            }
        </>
    );
};

export default Section;