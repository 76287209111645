import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import React, { useEffect, useRef, useState } from "react";
import Card from "../Card/Card"
import ReactPlayer from 'react-player';

export interface TileSlide {
    title: string;
    description: string;
    logoImageUrl: string;
}
interface LinkItem {
    url: string;
    isExternal: boolean;
    label: string;
    iconUrl: string;
}
export interface BannerSlide {
    url: string;
    alt: string;
    text: string;
    bannerLinks: LinkItem[];
    isVideo: boolean;
    bannerCardClass: string;
    isButton: boolean;
    buttonText: string;
    buttonUrl: string;
}
export interface CarouselSlideProps {
    carouselType: string;
    tileSlides: TileSlide[];
    bannerSlides: BannerSlide[];
    showDots: boolean;
    showArrows: boolean;
    showToSlide: number;
};

const CarouselSlide = ({
    carouselType,
    tileSlides,
    bannerSlides,
    showDots,
    showArrows,
    showToSlide
}: CarouselSlideProps) => {
    const [stokeOffSetCount, setStokeOffSetCount] = useState(0);
    const carouselRef = useRef<HTMLDivElement | null>(null);
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
    const [buttonPressed, setButtonPressed] = useState(false);
    const timer = useRef(0);
    let progressBar = 0;
    let carouselState: any, goToSlide: any, currentSlide = 0, totalItems: number;
    useEffect(() => {
        if (bannerSlides.length > 0) {
            setTimeout(() => { slideAutoPlay() }, 2000);
        }
    }, [carouselRef]);

    const slideAutoPlay = () => {
        goToSlide(currentSlide);
        if (timer.current > 0) {
            clearInterval(timer.current);
            progressBar = 0;
        }
        timer.current = window.setInterval(() => { updateProgressCircle() }, 80);
    };
    const slideButton = (index: number) => {
        if (buttonPressed) {
            setButtonPressed(false);
        }
        currentSlide = index;
        setCurrentBannerIndex(index);
        slideAutoPlay();
    };
    const slidePauseResume = () => {
        if (!buttonPressed) {
            setButtonPressed(true);
            if (timer.current > 0) {
                clearInterval(timer.current);
            }
        } else {
            progressBar = stokeOffSetCount;
            setButtonPressed(false);
            timer.current = window.setInterval(() => { updateProgressCircle() }, 80);
        }
    };

    const updateProgressCircle = () => {
        if (progressBar >= 125) {
            setStokeOffSetCount(0);
            clearInterval(timer.current);
            let nextSlide = currentSlide + 1;
            if (nextSlide >= carouselState.totalItems) {
                nextSlide = nextSlide - carouselState.totalItems;
            }
            currentSlide = nextSlide;
            setCurrentBannerIndex(nextSlide);
            slideAutoPlay();
        } else {
            progressBar = progressBar + 1;
            setStokeOffSetCount(progressBar);
        }
    }

    const CustomButtonGroup = ({ ...rest }) => {
        carouselState = rest.carouselState;
        goToSlide = rest.goToSlide;
        totalItems = carouselState.totalItems;
        return (<></>)
    }
    const simulateSwitch = (carouselType: string, tileSlides: any, bannerSlides: any,
        showDots: boolean,
        showArrows: boolean,
        showToSlide: number) => {
        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: showToSlide,
                showDots: showToSlide <= 3,            
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: showToSlide,
                showDots: showToSlide <= 3 
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: showToSlide,
                showDots: showToSlide <= 3  
            }
        }

        switch (carouselType) {
            case "Banner":
                return (
                    <>
                        <div style={{ position: "relative" }}>
                            <Carousel responsive={responsive}
                                swipeable={false}
                                autoPlay={false}
                                ssr={false}
                                infinite={false}
                                removeArrowOnDeviceType={["desktop", "tablet", "mobile"]}
                                rewindWithAnimation={true}
                                rewind={true}
                                customButtonGroup={<CustomButtonGroup />}>                                   
                                {bannerSlides.map((slide: any, index: number) => {
                                    return (
                                        <>
                                            {slide.isVideo ? (<>
                                                <div className="banner-container">
                                                    <div className='player-wrapper'>
                                                    <ReactPlayer className='react-player'                                
                                                        url={slide.url}
                                                        controls={true}
                                                        width='100%'
                                                        height='100%'
                                                        />
                                                    </div>
                                                </div> 
                                            </>) : 
                                            (<>
                                                <div>                                            
                                                    <div ref={carouselRef} className={`banner-container ${slide.bannerCardClass}`}>
                                                        <div className="container liquid-container hidden-xs hidden-sm">
                                                            <div className="bhp-banner-content-wrap">
                                                                <div className="bhp-banner-contents">
                                                                    <h2 className="bellSlimBold middle banner-text txtBold txtSize32 txtBlack no-margin-top line-height-38">
                                                                        {slide.text}
                                                                    </h2>
                                                                    <div className="spacer10 hidden-xs"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="hidden-lg hidden-md">
                                                        <div className="bhp-banner-content-wrap-small-media">

                                                            <div className="bhp-banner-contents">
                                                                <h2 className="bellSlimBold middle banner-text txtBold txtSize32 txtBlack no-margin-top line-height-38"> {slide.text}</h2>
                                                                {/* {bannerImages[0].isButton ? (<>
                                                                        <button className="btn btn-default">{bannerImages[0].buttonText}</button>
                                                                </>):(<></>)} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                        </>
                                    );
                                })}
                            </Carousel>
                            <div>
                                <ul className="bhp-banner-carousel-buttons" role="tablist" id="hpBannerCarouselButtons">
                                    {bannerSlides.map((slide: any, index: number) => {
                                        return (
                                            <li key={index} className={`bhp-banner-carousel-button-wrap ${currentBannerIndex == index ? 'bhp-active' : ''}`} role="presentation">
                                                <button className="bhp-banner-carousel-button" type="button" onClick={() => slideButton(index)}
                                                    role="tab" aria-label={`${index + 1} of ${bannerSlides.length}`} aria-controls="carousel-banner-1" tabIndex={-1}>{index + 1}</button>
                                            </li>
                                        );
                                    })}
                                </ul>
                                <button className="bhp-banner-pause-btn" type="button" id="bannerCarouselPauseBtn" onClick={(e) => { slidePauseResume() }}
                                    data-pressed={buttonPressed} aria-label="Stop Carousel" data-play-label="Play Carousel"
                                    data-pause-label="Stop Carousel">
                                    <div aria-hidden="true" className="bhp-banner-pause-btn-bg"></div>
                                    <svg aria-hidden="true" focusable="false" tabIndex={-1} className="bhp-banner-pause-btn-progress"
                                        viewBox="0 0 40 40">
                                        <circle focusable="false" tabIndex={-1} className="" cx="20" cy="20" r="20"
                                            id="bannerCarouselPauseBtnProgress" style={{ strokeDashoffset: -stokeOffSetCount }} />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </>
                );
            case "Tile":
                return (
                    <>
                        <Carousel responsive={responsive}
                            autoPlay={false}
                            ssr={false}
                            infinite={false}
                            showDots={responsive.desktop.showDots}
                            centerMode={false}
                            removeArrowOnDeviceType={["desktop","tablet", "mobile"]}>
                            {tileSlides?.map((tile: any, index: number) => {
                                return (
                                    <Card
                                        title={tile.title}
                                        description={tile.description}
                                        body=""
                                        keyNumber={index}
                                        logoImageUrl={tile.logoImageUrl}
                                        stackedImageUrl={tile.stackedImageUrl}
                                        showBorder={false}
                                        customCardClass={""}
                                        customTitleClass={""}
                                        custombodyClass={"noBorder"}
                                        customFooterClass=""
                                        customImageClass=""
                                        custombodyHeight={"careerSearch"}
                                        customCardWdithClass=""
                                        stackedImageFullWidth={false}
                                        url={tile.url}
                                    />
                                );
                            })}
                        </Carousel>
                    </>
                );
            default:
                return null;
        }
    };
    return (
        <>
            {simulateSwitch(carouselType,
                tileSlides,
                bannerSlides,
                showDots,
                showArrows,
                showToSlide)}
        </>
    )
};

export default CarouselSlide;