import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/homePage/homePage';
import CareerPage from './pages/careerPage/careerPage';
import CareerSearch from './pages/careerPage/careerSearchPage';
import CareerDetail from './pages/careerPage/careerDetailPage';
import Contactus from './pages/contactusPage/contactusPage';
import Resources from './pages/resourcesPage/resourcesPage';
import ResourcesDetail from './pages/resourcesPage/resourceDetailPage';
import Blog from './pages/blogPage/blogList';
import NotFoundPage from './pages/notFoundPage/notFoundPage';
import BlogsDetail from './pages/blogPage/blogDetail';
import LeaderDetail from './pages/aboutUsPage/leaderDetailPage';
import LeaderList from './pages/aboutUsPage/leaderListpage';
import News from './pages/newsPage/newsPage';
import NewsArchives from './pages/newsPage/newsArchives';
import CareerNew from './pages/careerPage/careerNewPage';
const Router = () =>{
    return (
        <Routes>
            <Route path='/' element={<HomePage/>}/>
            <Route path='/career' element={<CareerNew/>}/>
            <Route path='/resources' element={<Resources/>}/>
            <Route path='/resources/:resource_name' element={<ResourcesDetail/>}/>
            <Route path='/career/:jobCategory' element={<CareerSearch/>}/>
            <Route path='/career-details/:jobId' element={<CareerDetail/>} />
            <Route path='/contactus' element={<Contactus/>}/>
            <Route path='/blog-list' element={<Blog/>}/>
            <Route path='/blog-list/:name' element={<BlogsDetail/>}/>
            <Route path='/leadership' element={<LeaderList/>}/>
            <Route path='/leader/:leadername' element={<LeaderDetail/>}/>
            <Route path='/news' element={<News/>}/>
            <Route path='/news-archives' element ={<NewsArchives/>}/>
            <Route path="*" element={<NotFoundPage />} />
            {/* <Route path='/career-new' element={<CareerNew/>}/> */}
        </Routes>
      );
}

export default Router;