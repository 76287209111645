import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const notFoundPage = () => {
  return (

<div>
  

      <div className='text-center pad-30'>
        <h1 className='txtBold'>404</h1>
        <p className='txtBold'> Oops! The page you are looking for does not exist.</p>
      </div>
      <div className="bgWhite pad-15-xs">
      <div className="container liquid-container">
        <div className='pad-30'>
      <div className='card custom_card rounded custom_rounded bg-white border-0 Search_start_tile'>
      <div className="row pb-2">
        <div className="col-12 col-sm-12 col-md-4 d-flex">
          <div className="" role=""><div className="card-body">
            <div className="card_body_info">
            <div className="Search_result_img_title">
              <h5 className=" Search_result_card_title"></h5>
              <div className="card_header_info text-center kick_start_body_tile">

              </div>
            </div>
          </div>
          </div>
          </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 d-flex">
            <div>
              <div className="card-body">
                <div className="card_body_info">
                  <div>
                    <h5 className=" bellSlimBold  txtSize16">Explore portal</h5>
                  </div>
                  <div className="card_header_info text-center kick_start_body_tile">
                    <div className="text-left">
                      <ul>
                        <li className="text_style listStyleNone txtBlue txtSize14 txtSizeMobile3 pad-5-top pad-5-bottom">
                        <a href="/career" className="icon more-link more-link-pad-left txtBold line-height-07"></a>
                        <a href="/career" className='txtBold txtSize14'>Careers</a>
                        
                        </li>
                        <li className="text_style listStyleNone txtBlue txtSize14 txtSizeMobile3 pad-5-top pad-5-bottom">

                        <a href="/resources" className="icon more-link more-link-pad-left txtBold line-height-07"></a>
                        <a href="/resources" className='txtBold txtSize14'>Resources</a>
                       
                        </li>
                       </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-4 d-flex">
            <div>
              <div className="card-body">
                <div className="card_body_info">
                  <div>
                    <h5 className="bellSlimBold txtSize16">Contact us</h5>
                  </div>
                  <div className="card_header_info text-center kick_start_body_tile">
                    <div className="text-left">
                      <ul>
                        <li className="text_style listStyleNone txtBlue txtSize14 txtSizeMobile3 pad-5-top pad-5-bottom">
                        <a href="/contactus" className="icon more-link more-link-pad-left txtBold line-height-07"></a>
                        <a href="/contactus" className='txtBold txtSize14'>Contact Us</a>
                                   
                            </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       </div>
      </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default notFoundPage;