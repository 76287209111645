import React, { useEffect, useState, useRef  } from "react";
import { useNavigate } from "react-router-dom";

interface Collaborations
{
    title: string;
    subTitle?: string;
    description: string;
    logoImageUrl:string;
}

interface CollaborationsProps {
    mainTitle:string
    customMainTitleClass:string
    customBodyClass:string
    customTitleClass:string
    customImageClass:string
    collaborations:Collaborations[];
}
const Collaborations = ({
    mainTitle,
    customMainTitleClass,
    customBodyClass,
    customTitleClass,
    customImageClass,
    collaborations    

}: CollaborationsProps) => {

    return(
        
        <>
        <div className="container liquid-container">
            <div className="spacer30"></div>
            <div className="spacer30"></div>
            {mainTitle &&(
                <div>

                <h2 className={customMainTitleClass}>{mainTitle}</h2>

                  
                </div>
            )}
            <div className="inline-blck w-100">
           {collaborations.map((collaboration:any,index:number)=>
           {
            return(
                <>
                
                <div className={customBodyClass} key={index}>

                    <div className="col-md-4 col-sm-4 pad-30">
                    <img className={customImageClass} src={collaboration.logoImageUrl}/>
                    </div>
                    <div className="col-md-8 col-sm-8">
                        <div>
                            <h2 className={customTitleClass}>{collaboration.title}</h2></div>
                        <div className="spacer15"></div>
                        <p>{collaboration.description}</p> 
                        </div>
                        <div className="clear"></div>
                </div>
                <hr className="no-margin"/>              
                  
                   
                </>
            );

           })}           
                  
            </div>    
        </div>
        </>

    );
};
export default Collaborations;