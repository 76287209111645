import React, { useState } from "react";
import { useParams,NavLink,useNavigate} from "react-router-dom";
const NavBar = () =>{
    const navigation = useNavigate();
    const [navBarOpen,setNavBarOpen] = useState(false);
    const [showSubMenu, setShowSubMenu] = useState(false);
   
    const navButtonOpenClose = () => {
        if(!navBarOpen){
            document.body.classList.add('connector-active');  
            setNavBarOpen(true);
            setShowSubMenu(false);   
        }else{
            document.body.classList.remove('connector-active');    
            setNavBarOpen(false);   
            setShowSubMenu(false); 
        }                  
    };    
    
    const navBarClose = () => {
        document.body.classList.remove('connector-active');    
        setNavBarOpen(false);
    }
   
    
    return(
        <>
            <div className="connector-mobile-bar">
                <div className="connector-active-lob-title">
                        
                </div>
            </div>
            <nav role="navigation" className="connector-nav">
                <div className="connector-brand hidden-xs hidden-sm">
                        <NavLink to="/">
                            <img src="/content/img/BCEGlobalTech_White_reversed.png" alt="logo" style= {{ height: "100%" }} />
                        </NavLink>
                </div>
                <ul className="connector-areas">
                    <li className="connector-area no-sub-nav">
                        <NavLink to="/career" className="pointer" role="button" tabIndex={-1} onClick={(e) => { navButtonOpenClose() }}>
                            <span className="bellSlim">Careers</span>
                        </NavLink>
                    </li>
                    <li className="connector-area no-sub-nav">
                        <NavLink className="pointer" role="button" tabIndex={-1} to="/resources" onClick={(e) => { navButtonOpenClose() }}>
                            <span className="bellSlim">Resources</span>
                        </NavLink>
                    </li>
                    {/* <li  className="connector-area">                       
                       <a className="pointer" role="button" onClick={() => setShowSubMenu(!showSubMenu)}>
                       <span className="bellSlim">News & events</span>                       
                       </a>
                        <div className="menu-flyout hidden-sm hidden-xs">
                            <div className="menu-flyout-root">
                                <ul className="sub-nav-root">
                                    <li className="no-sub-nav">
                                        <NavLink className="d-flex justify-content-between sub-nav-level-1" role="button" to="/news" onClick={(e) => { navButtonOpenClose() }}>
                                            <span className="bellSlim txtSize16">News</span>
                                        </NavLink>
                                    </li>
                                    <li className="no-sub-nav">
                                        <NavLink className="d-flex justify-content-between sub-nav-level-1" role="button" to="/blog-list" onClick={(e) => { navButtonOpenClose() }}>
                                            <span className="bellSlim txtSize16">Blog</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="connector-lob-flyout myBell hidden-md hidden-lg">
                            <div className="container">
                                <ul className="connector-lob-flyout-content">
                                    <li className="connector-lob no-sub-nav">
                                        <NavLink className="sub-nav-level-1" role="button" to="/news" onClick={(e) => { navButtonOpenClose() }}>
                                            <span className="bellSlim txtSize16">News</span>
                                        </NavLink>
                                    </li>
                                    <li className="connector-lob no-sub-nav">
                                        <NavLink className="sub-nav-level-1" role="button" to="/blog-list" onClick={(e) => { navButtonOpenClose() }}>
                                            <span className="bellSlim txtSize16">Blog</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>                       
                    </li>                     */}
                </ul>
                <ul className="federal-bar-mobile">
                        <li>
                            <NavLink className="connector-lob-no-href " role="button" tabIndex={-1} to="/contactus"  onClick={(e) => {navButtonOpenClose()}}>
                                <span className="bellSlim">Contact us</span>
                            </NavLink>                         
                        </li>
                </ul>
            </nav>
            <div className="connector-settings bce-mobile-header">
                    <NavLink to= "/" className="connector-brand hidden-md hidden-lg bce-mobile-header-logo" onClick={(e) => {navBarClose()}}>
                        <img src="/content/img/BCEGlobalTech_White_reversed.png" alt="logo" />
                    </NavLink>
                    <button type="button" onClick={(e) => {navButtonOpenClose()}} className={`connector-nav-open-button hidden-md hidden-lg ${navBarOpen ? 'active':''}`} title="Open mobile nav"
                            aria-label="Open mobile nav">
                        <i className="icon icon-mobile-menu" aria-hidden="true"></i>
                        <i className="icon icon-plus" aria-hidden="true"></i>
                    </button>
                    <button className="btn btn-primary-white floatR hidden-sm hidden-xs" onClick={(e) => {navigation('/contactus')}} >Contact Us</button>
                    <div className="clear"></div>
                </div>
        </>
    );
};

export default NavBar;