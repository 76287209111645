import React,{useEffect,useState} from "react";
import { useLocation } from "react-router-dom";
interface TabList {
    name:string,
    navUrl:string,
    iconUrl:string
}
interface TabProps {
    tabList: TabList[]
}
const Tab = ({tabList}:TabProps) => {
    const [activeTabIndex,setActiveTabIndex] = useState(0);
    const tabRoute = useLocation();
    const selectTab = (index:any) => {
        setActiveTabIndex(index);
    };
    useEffect(() => {
        const currentIndex = parseInt(tabRoute.hash.split('#tab')[1]) - 1;
        setActiveTabIndex(currentIndex);
        if(tabRoute.hash === ''){
            setActiveTabIndex(0);
        }
    },[]);
    return(
        <>
            <div className="container liquid-container tab_index">
                <ul className="tab_list">
                    {tabList.map((tab,index) => (
                        <li className={`tab_item ${activeTabIndex == index ? 'tab_active' : ''}`} 
                            key={index} onClick={(e) => (selectTab(index))}>
                            <a href={tab.navUrl} className="bellSlimBold tab_text">
                                <span>{tab.name}</span>
                            </a>
                        </li>
                    ))}                    
                </ul>
            </div>  
        </>
    );
};

export default Tab;