import React from "react";
import { Link, useParams,useNavigate } from "react-router-dom";

interface Icon {
    iconUrl:string;
}

interface Links { 
    
    label: string;
    url: string;
    icon: Icon;
    isExternal:boolean;
}


interface CardProps{
    title: string;
    subTitle?: string;
    description: string;
    body: string;
    keyNumber:number;
    logoImageUrl:string;
    stackedImageUrl: string;
    showBorder: boolean;
    customCardClass?: string;
    customTitleClass?: string;
    custombodyClass?: string;
    customFooterClass: string;
    customImageClass: string;
    custombodyHeight:string;
    customCardWdithClass:string;
    stackedImageFullWidth:boolean;
    url:string;
    customCardLinks?:Links[];
    customCardLinksClass?:string; 
    customSubTitleClass?:string;   
}

const Card = ({
    title,
    subTitle,
    description,
    body,
    keyNumber,
    logoImageUrl,
    stackedImageUrl,
    showBorder,
    customCardClass,
    customTitleClass,
    custombodyClass,
    customFooterClass,
    customImageClass,
    custombodyHeight,
    customCardWdithClass,
    stackedImageFullWidth,
    url,
    customCardLinks,
    customCardLinksClass,
    customSubTitleClass
}:CardProps) =>{

    const navigation = useNavigate();


    return(        
            <div {...(url ? {onClick:() => {window.location.href = `/${url}`}} : {})} key={keyNumber} className={`pad-15-left pad-0-left-xs pad-15-top middle txtCenter ${customCardClass ? customCardClass : 'col-md-4 col-sm-4'}`}>
                <div className={`card custom_card rounded ${custombodyClass ? custombodyClass : ''} ${url ? 'cursor-pointer' : ''}`}>
    
                { stackedImageUrl && stackedImageFullWidth == true ? ( 
                    
                    <img src={stackedImageUrl} className="w-100" alt= {title}/>
                    
                    ) : stackedImageUrl && (
                        
                        <img src={stackedImageUrl} alt= {title}/>
    
                    ) }   
                  
    
                    <div className={`card-body ${custombodyHeight}`}>
                        <div className="card_body_info">
                            {logoImageUrl &&(
                                <div className="card_body_info_logo"><img src={logoImageUrl} alt={description}/></div>
                            )}
                            <h2 className={`txtBlack ${customTitleClass}`}>
                                {title}
                            </h2>
                            {subTitle &&(
                                <>
                                    <div className={`${customSubTitleClass}`}>{subTitle}</div>
                                  
                                </>                                
                            )}
                            <div className={`card_header_info`}>
                                <p className={`text_style article_shot_description`}>
                                        {description}
                                </p> 
                                {customCardLinks &&(
                                <>
                                    <a className={`${customCardLinksClass}`} href={customCardLinks[0].url}>{customCardLinks[0].label}</a>                                                  
                                </>
                                )}
                            </div>
                       
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default Card;