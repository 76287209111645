import NewsArchives from "../../json/newsarchivespage.json";
import TogglePanel from "../../components/TogglePanel/TooglePanel";
import SubFooter from "../../components/SubFooter/SubFooter";
import MetaTag from "../../components/MetaTag/MetaTag";

const NewsArchivesData = NewsArchives.accordionSection;
const newsarchives: React.FC = () => {
    
    return (
        <>
        <MetaTag title={ "BCE GlobalTech - News Archives" } description={ "" }/>
        <div className="container liquid-container pad-mobile">
            <div className="spacer20 clear hidden-xs hidden-sm"></div>
            <h1 className="news-title">{NewsArchives.title}</h1>
            <p className="news-description">{NewsArchives.description}</p>
            <div className="spacer40 clear hidden-xs hidden-sm"></div>
            <div className="spacer30 hidden-lg hidden-md"></div>
            <div className="accordion-container">
                {NewsArchivesData.map((item, index) => (
                <TogglePanel key={index} title={item.title} content={item.content} readMore={item.readMore}/>
                ))}
            </div>
        </div>
        <SubFooter
            title="Need more information?"
            buttonText="Contact us"
            buttonUrl="/contactus"
            isCustomInfo={true}
            moreInformationText="Connect with us today to learn more about our services and solutions"
            moreInformationMailToId="Email: info@bceglobaltech.com"
        />
        </>
    )

}
export default newsarchives;