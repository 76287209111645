import React, { useEffect, useState } from "react";
import {useParams,useLocation} from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import JobFilterData from "../../json/jobFilter.json";
import { NavLink } from "react-router-dom";
import ReactPaginate from 'react-paginate';
const jobFilterList = JobFilterData.jobFilters;

interface KeyValue{
  value:string;
}
interface Job {
  jobId: string;
  jobTitle: string;
  jobDescription: string;
  location: string;
  jobCategory:string;
  jobType:string,
  workProfile:string,
  experience:string,    
  noOfOpenings:number,        
  keyResponsibility:KeyValue[],
  technologySkills:KeyValue[],
  requiredQualification:KeyValue[],
  workingConditions?:KeyValue[],
  ourDetails:string,
  ourOffer:KeyValue[],
  additionalInformation:KeyValue[],
  status:string,
  effectiveFrom:string,
  effectiveTo:string,
  createdOn: string,
  publishedOn: string,
  completedOn: string,
  holdOn: string,
  deletedOn: string
}

interface SearchResultProps {
  bannerSearchText:string;
  onFiltersChange: (filters: {
    category: Set<string>;
    jobType: Set<string>;
  }) => void;
  jobSpecifications:Job[];
}

const SearchResult: React.FC<SearchResultProps> = ({onFiltersChange,
  bannerSearchText,
  jobSpecifications
}) => { 
  const tabRoute = useLocation();
  const { jobCategory } = useParams<{ jobCategory: string }>();
  const [interSearchFilter,setInterSearchFilter] = useState("");
  const [totalJobCount,setTotalJobCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [activeKeys, setActiveKeys] = useState<string[]>([]);
  const [searchInputs, setSearchInputs] = useState<{ [key: number]: string }>({});
  const [selectedFilters, setSelectedFilters] = useState<{
    category: Set<string>;
    jobType: Set<string>;
  }>({
    category: new Set(),
    jobType: new Set(),
  });
  const [initialLoad, setInitialLoad] = useState(true); 
  useEffect(() => {
    if (initialLoad) {
      const categoryFilterIndex = jobFilterList.findIndex(filter => filter.type === "Category");
      const categoryFilter = jobFilterList[categoryFilterIndex];

      if (categoryFilter) {
        const matchedCategory = categoryFilter.items.find(item => item.url === tabRoute.hash.split("?")[1].split("=")[1]);

        if (matchedCategory) {
          setSelectedFilters(prevFilters => {
            const updatedFilters = { ...prevFilters };
            updatedFilters.category.add(matchedCategory.name);
            onFiltersChange(updatedFilters);
            return updatedFilters;
          });

          setActiveKeys([categoryFilterIndex.toString()]);
        }
      }      
      setInitialLoad(false);
    }
    setCurrentPage(0);    
  }, [selectedFilters, interSearchFilter]);
  
  useEffect(() => {
    if (selectedFilters.category.size > 0 || selectedFilters.jobType.size > 0) {      
      jobOpeningCount();
    } else {
      let jobVacant = 0;
      jobSpecifications.filter((job:any) => job.status === "Published").forEach(element => {
        jobVacant += element.noOfOpenings;
      });
      setTotalJobCount(jobVacant);
    }
  },[jobSpecifications,selectedFilters]);
    
  const filteredJobResults = bannerSearchText !== "" ? jobSpecifications.filter(job => 
    job.jobTitle.includes(bannerSearchText) || job.jobDescription.includes(bannerSearchText) || 
    job.jobId.includes(bannerSearchText) || job.location.includes(bannerSearchText) || job.jobType.includes(bannerSearchText)) 
  : interSearchFilter !== "" ? jobSpecifications.filter(job =>
    (selectedFilters.category.size === 0 || selectedFilters.category.has(job.jobCategory)) &&
    (selectedFilters.jobType.size === 0 || selectedFilters.jobType.has(job.jobType)) && 
    (job.jobTitle.includes(interSearchFilter) || job.jobId.includes(interSearchFilter) 
    || job.jobDescription.includes(interSearchFilter) || job.location.includes(interSearchFilter) 
    || job.jobType.includes(interSearchFilter))) 
    
    : jobSpecifications.filter(job =>
    (selectedFilters.category.size === 0 || selectedFilters.category.has(job.jobCategory)) &&
    (selectedFilters.jobType.size === 0 || selectedFilters.jobType.has(job.jobType)));

  const hasJobResults = filteredJobResults?.length > 0;
  const totalPosition = filteredJobResults.length;

  const handleRemoveCategoryFilter = (category: string) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = new Set(prevFilters.category);
      updatedFilters.delete(category);
      return { ...prevFilters, category: updatedFilters };
    });
    
  };

  const handleRemoveJobTypeFilter = (jobType: string) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = new Set(prevFilters.jobType);
      updatedFilters.delete(jobType);
      return { ...prevFilters, jobType: updatedFilters };
    });
  };

  const handleInterSearchFilter = (e:any) => {
      setInterSearchFilter(e.target.value);
  };
  
  const handlePageChange = (page:number) => {
    setCurrentPage(page);
  };

  const clearFilters = () => {
    const clearedFilters = {
      category: new Set<string>(),
      jobType: new Set<string>(),
    };
    setSelectedFilters(clearedFilters);
    onFiltersChange(clearedFilters);
  };

  const handleSelect = (eventKey: string | null) => {
    if (!eventKey) return;
    setActiveKeys((prevActiveKeys) => {
      if (prevActiveKeys.includes(eventKey)) {
        return prevActiveKeys.filter((key) => key !== eventKey);
      } else {
        return [...prevActiveKeys, eventKey];
      }
    });
  };

  
  const renderIcon = (eventKey: string) => {
    return activeKeys.includes(eventKey) ? (
      <span className="icon icon-minus"></span>
    ) : (
      <span className="icon icon-plus"></span>
    );
  };

  const handleSearchChange = (index: number, value: string) => {
    setSearchInputs((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  const handleCheckboxChange = (
    sectionTitle: string,
    value: string,
    checked: boolean
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (sectionTitle === "Category") {
        if (checked) {
          updatedFilters.category.add(value);
        } else {
          updatedFilters.category.delete(value);
        }
      } else if (sectionTitle === "JobType") {
        if (checked) {
          updatedFilters.jobType.add(value);
        } else {
          updatedFilters.jobType.delete(value);
        }
      }

      onFiltersChange(updatedFilters);
      return updatedFilters;
    });
  };


  // Function to accordion visibility in mobile view
  const [isAccordionVisible, setAccordionVisible] = useState(false);
  const handleToggleAccordion = () => {
    setAccordionVisible(!isAccordionVisible);
    document.body.classList.add('hidden-bg');
  }
  const closeFilterBox = () => {
    setAccordionVisible(false);
    document.body.classList.remove('hidden-bg');
  };  

  const itemsPerPage = 6
  const startIndex = currentPage;
  const currentItems = filteredJobResults.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(filteredJobResults.length / itemsPerPage);


  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % filteredJobResults.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setCurrentPage(newOffset);
  };
  
  const jobOpening = (itemName:string) => {
    let jobVacant = 0;
    let jobsCategory = jobSpecifications.filter((job:any) => job.jobCategory === itemName);
    jobsCategory.forEach(element => {
      jobVacant += element.noOfOpenings;
    });
    return jobVacant;
  };
 const jobTypeCount = (itemName:string) => {
    let jobTypeCount = 0;
    if (selectedFilters.category.size > 0){
      selectedFilters.category.forEach(element => {
        let jobTypes = jobSpecifications.filter((job:any) => job.jobType === itemName && job.jobCategory === element);
        jobTypes.forEach(element => {
          jobTypeCount += element.noOfOpenings;
        });
      });   
    } else{
      let jobTypes = jobSpecifications.filter((job:any) => job.jobType === itemName);
        jobTypes.forEach(element => {
          jobTypeCount += element.noOfOpenings;
        });
    }
    return jobTypeCount;
 }
  const jobOpeningCount = () =>{
    let jobOpeningCount = 0;

    selectedFilters.category.forEach(category => {
      jobSpecifications.filter((job:any) => job.jobCategory === category).forEach(element => {
        jobOpeningCount += element.noOfOpenings;
      });
    });
    if(selectedFilters.jobType.size > 0 && selectedFilters.category.size == 0){
      jobOpeningCount = 0;      
      jobSpecifications.filter((job:any) => selectedFilters.jobType.has(job.jobType))
        .forEach(element => {
                jobOpeningCount += element.noOfOpenings;
      });
    }
    if(selectedFilters.jobType.size > 0 && selectedFilters.category.size > 0){
      jobOpeningCount = 0;      
      jobSpecifications.filter((job:any) => selectedFilters.jobType.has(job.jobType) 
              && selectedFilters.category.has(job.jobCategory)).forEach(element => {
                jobOpeningCount += element.noOfOpenings;
              });            
      
    }
    setTotalJobCount(jobOpeningCount);
  };
  const removeHtml = (str:any) => {
    const doc = new DOMParser().parseFromString(str, 'text/html');
    return doc.body.textContent || doc.body.innerText;
  };
  const TruncatedJobDescription = (description:any) => { 
    const replaceDescription = description.replace("<h3>Requirements</h3>","").replace("<h3>Benefits</h3>","")   
    const cleanedText = removeHtml(replaceDescription);
    const truncatedDescription = cleanedText.length > 200 ? cleanedText.slice(0,150) + '...' : cleanedText;
    return <p className="txtSize16">{truncatedDescription}</p>
  }
  return (
    <>
      {jobSpecifications.length == 0 ?
      (<>
         <div id="brf-page-loader" className="loader-fixed bgWhite container-flex-box brf-page-loader">
          <svg className="loading-indicator-circle middle-align-self" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs>
              <linearGradient id="loadingIndicatorGradient1" x1="0" x2="0" y1="10%" y2="90%">
              <stop offset="0" stop-color="#04569b"></stop><stop offset="1" stop-color="#97b6d2"></stop>
              </linearGradient>
              <linearGradient id="loadingIndicatorGradient2" x1="0" x2="0" y1="90%" y2="10%"><stop offset="0" stop-color="#97b6d2"></stop><stop offset="1" stop-color="#fff"></stop></linearGradient></defs>
              <path fill="url(#loadingIndicatorGradient1)" d="M24 3C12 3 3 12 3 24S12 45 24 45v-4c-10 0-17-8-17-17S15 7 24 7v-4z"></path><path fill="url(#loadingIndicatorGradient2)" d="M24 3v4c10 0 17 8 17 17S34 41 24 41v4c12 0 21-10 21-21S36 3 24 3z"></path>
          </svg>
          <div className="page-loader-msg middle-align-self">Loading data, please wait</div></div>
      </>)
      :(<>
          <div className="bgWhite pad-15-xs">
            {isAccordionVisible && (
                <div className="search-filter-popup">
                        <div className="search-filter-left-box hidden-sm hidden-lg hidden-md">
                            <div className="search-filter-left-box-header">
                              <h2 className="txtBlack txtSize20 txtSize18-xs bellSlimBold">
                                Refine your search
                              </h2>
                              <i className="icon icon-close visible-xs txtSize18 search-filter-box-close-xs" onClick={closeFilterBox}></i>
                            </div>
                            <div className="clear spacer10"></div>
                            <Accordion
                              className="search-filter-accordion-list bgGray19"
                              activeKey={activeKeys}
                              onSelect={(e) => handleSelect(e as string | null)}>
                                {jobFilterList.map((section, index) => {
                                  const searchValue = searchInputs[index] || "";
                                  const filteredTitles = section.items.filter((item) =>
                                    item.name.toLowerCase().includes(searchValue.toLowerCase())
                                  );

                                  return (
                                    <Accordion.Item eventKey={index.toString()} key={index}>
                                      <div className="search-filter-accordion-item">
                                        <Accordion.Header>
                                          <span className="search-filter-accordion-item-title bellSlim txtBold">
                                            {section.type}
                                          </span>
                                          {renderIcon(index.toString())}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <div className="clear spacer30"></div>
                                          <div className="search-filter-result-search-bar">
                                            <input
                                              type="text"
                                              placeholder={`Search in ${section.type}`}
                                              value={searchValue}
                                              onChange={(e) => handleSearchChange(index, e.target.value)}
                                            />
                                            <div className="clear spacer20"></div>
                                          </div>
                                          <ul className="search-filter-accordion-sub-list">
                                            {filteredTitles.map((item, subIndex) => (
                                              <li
                                                className="search-filter-accordion-sub-item"
                                                key={subIndex}
                                              >
                                                <input
                                                  type="checkbox"
                                                  name={`${index}-${subIndex}`}
                                                  className="search-filter-checkbox"
                                                  checked={
                                                    section.type === "Category"
                                                      ? selectedFilters.category.has(item.name)
                                                      : selectedFilters.jobType.has(item.name)
                                                  }
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      section.type,
                                                      item.name,
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                                <span className="search-filter-text">
                                                  {item.name}&nbsp;({section.type === "Category" ? 
                                                          jobOpening(item.name) : 
                                                          section.type === "JobType" ? jobTypeCount(item.name) : 0})
                                                </span>
                                              
                                              </li>
                                            ))}
                                          </ul>
                                        </Accordion.Body>
                                      </div>
                                    </Accordion.Item>
                                  );
                                })}
                            </Accordion>
                        </div>
                </div>        
            )}
             <div  className="container liquid-container">
                  <div className="career-search-container">
                      <div className="search-filter-box">
                        <div className="search-filter-left-box hidden-xs">
                            <div className="search-filter-left-box-header">
                              <h2 className="txtBlack txtSize20 txtSize18-xs bellSlimBold">
                                Refine your search
                              </h2>                        
                            </div>
                            <div className="clear spacer10"></div>
                            <Accordion
                              className="search-filter-accordion-list bgGray19"
                              activeKey={activeKeys}
                              onSelect={(e) => handleSelect(e as string | null)}>
                                {jobFilterList.map((section, index) => {
                                  const searchValue = searchInputs[index] || "";
                                  const filteredTitles = section.items.filter((item) =>
                                    item.name.toLowerCase().includes(searchValue.toLowerCase())
                                  );

                                  return (
                                    <Accordion.Item eventKey={index.toString()} key={index}>
                                      <div className="search-filter-accordion-item">
                                        <Accordion.Header>
                                          <span className="search-filter-accordion-item-title bellSlim txtBold">
                                            {section.type}
                                          </span>
                                          {renderIcon(index.toString())}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <div className="clear spacer30"></div>
                                          <div className="search-filter-result-search-bar">
                                            <input
                                              type="text"
                                              placeholder={`Search in ${section.type}`}
                                              value={searchValue}
                                              onChange={(e) => handleSearchChange(index, e.target.value)}
                                            />
                                            <div className="clear spacer20"></div>
                                          </div>
                                          <ul className="search-filter-accordion-sub-list">
                                            {filteredTitles.map((item, subIndex) => (
                                              <li
                                                className="search-filter-accordion-sub-item"
                                                key={subIndex}
                                              >
                                                <input
                                                  type="checkbox"
                                                  name={`${index}-${subIndex}`}
                                                  className="search-filter-checkbox"
                                                  checked={
                                                    section.type === "Category"
                                                      ? selectedFilters.category.has(item.name)
                                                      : selectedFilters.jobType.has(item.name)
                                                  }
                                                  onChange={(e) =>
                                                    handleCheckboxChange(
                                                      section.type,
                                                      item.name,
                                                      e.target.checked
                                                    )
                                                  }
                                                />
                                                <span className="search-filter-text">
                                                  {item.name}&nbsp;({section.type === "Category" ? 
                                                          jobOpening(item.name) : 
                                                          section.type === "JobType" ? jobTypeCount(item.name) : 0})
                                                </span>
                                              
                                              </li>
                                            ))}
                                          </ul>
                                        </Accordion.Body>
                                      </div>
                                    </Accordion.Item>
                                  );
                                })}
                            </Accordion>
                        </div>
                        <div className="search-filter-right-box">
                            <div className="">
                              <ul className="search-filters-list">
                                {Array.from(selectedFilters.category).map((category, index) => (
                                  <li
                                    key={`category-${index}`}
                                    className="search-filters-item filter-sort-text"
                                  >
                                    <span>{category}</span>
                                    <i
                                      className="icon icon-close"
                                      onClick={() => handleRemoveCategoryFilter(category)}
                                    ></i>
                                  </li>
                                ))}
                                {Array.from(selectedFilters.jobType).map((jobType, index) => (
                                  <li
                                    key={`jobType-${index}`}
                                    className="search-filters-item filter-sort-text"
                                  >
                                    <span>{jobType}</span>
                                    <i
                                      className="icon icon-close"
                                      onClick={() => handleRemoveJobTypeFilter(jobType)}
                                    ></i>
                                  </li>
                                ))}
                                {(selectedFilters.category.size > 0 ||
                                  selectedFilters.jobType.size > 0) && (
                                  <li className="search-filters-item" onClick={clearFilters}>
                                    <span>Clear all</span>
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div className="clear spacer20"></div>   
                            <div className="search-filter-result-header">
                                <div className="search-filter-result-search-bar-result">
                                  <input type="text" value={interSearchFilter} placeholder="Search from below list" onChange={handleInterSearchFilter}/>
                                </div>
                                <div className="search-filter-result-sort-by">
                                
                                  <span>
                                    Sort by : 
                                  </span>
                                  <select>
                                    <option>Most recent</option>
                                  </select>
                                  <a className="visible-xs txtBlue search-filter-bar-xs" onClick={handleToggleAccordion}>
                                    Filter <i className="icon icon-filter"></i>
                                  </a>
                                </div>
                            </div> 
                            <div className="row">
                            <p className="bellSlimBold txtSize18 career-search-found">
                              {totalPosition} {totalPosition <= 1 ? "Position" : "Positions"} | {totalJobCount} {totalJobCount <= 1 ? "Opening found" : "Openings found"}
                            </p>
                            </div>
                            <div className="search-filter-result-body" id="search-filter-result-body">
                                {hasJobResults ? (
                                  currentItems.map((job:any, index:number) => (
                                    <div className="search-filter-result-item" key={index}>
                                      <div className="content-alignright">
                                        <NavLink to={`/career-details/${job.jobId}`} className="txtBlack txtSize20">
                                          {job.jobTitle}
                                        </NavLink>                                        
                                      </div>
                                      <div className="clear spacer10"></div>
                                      <div className="search-filter-result-icons">
                                        <p className="txtSize16 bellSlim txtBlack">
                                          <svg 
                                            width="20px" 
                                            height="20px" 
                                            viewBox="0 0 512 512"
                                            className="icon-Position"
                                            fill="#1C2033" 
                                            x="246" 
                                            y="246" 
                                            role="img" 
                                            xmlns="http://www.w3.org/2000/svg">
                                              <g fill="#1C2033">
                                                <path 
                                                  fill="currentColor" 
                                                  d="M253.924 127.592a64 64 0 1 0 64 64a64.073 64.073 0 0 0-64-64Zm0 96a32 32 0 1 1 32-32a32.037 32.037 0 0 1-32 32Z" />
                                                  <path 
                                                    fill="currentColor" 
                                                    d="M376.906 68.515A173.922 173.922 0 0 0 108.2 286.426l120.907 185.613a29.619 29.619 0 0 0 49.635 0l120.911-185.613a173.921 173.921 0 0 0-22.747-217.911Zm-4.065 200.444l-118.916 182.55l-118.917-182.55c-36.4-55.879-28.593-130.659 18.563-177.817a141.92 141.92 0 0 1 200.708 0c47.156 47.158 54.962 121.938 18.562 177.817Z" />
                                              </g>
                                          </svg>
                                          <span className="pad-left-10">{job.location}</span>
                                        </p>
                                        <p className="txtSize16 bellSlim txtBlack">
                                          <svg
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 24 24"
                                            className="icon-Position"
                                            fill="#1C2033"
                                            x="246"
                                            y="246"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g fill="#1C2033">
                                              <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M4 15h13.865a1 1 0 0 0 .768-1.64L15 9l3.633-4.36A1 1 0 0 0 17.865 3H4v18"
                                              />
                                            </g>
                                          </svg>
                                          <span className="pad-left-10">{job.jobId}</span>
                                        </p>
                                        <p className="bellSlimBold txtSize16 floatR">{job.noOfOpenings} {job.noOfOpenings == 1 ? " Opening" :  " Openings"}</p>
                                      </div>
                                      {TruncatedJobDescription(job.jobDescription)}
                                    </div>
                                  ))                                  
                                ) : (
                                  <p className="txtCenter">
                                    There are no jobs for your search criteria.
                                    <br />
                                    Please search again.
                                  </p>
                                )}
                                <ReactPaginate
                                      key={totalPages}
                                      nextLabel="next >"
                                      onPageChange={handlePageClick}
                                      pageRangeDisplayed={5}
                                      marginPagesDisplayed={2}
                                      pageCount={totalPages}
                                      previousLabel="< previous"
                                      pageClassName="page-item"
                                      pageLinkClassName="page-link"
                                      previousClassName="page-item"
                                      previousLinkClassName="page-link"
                                      nextClassName="page-item"
                                      nextLinkClassName="page-link"
                                      breakLabel="..."
                                      breakClassName="page-item"
                                      breakLinkClassName="page-link"
                                      containerClassName="pagination"
                                      activeClassName="active"
                                      renderOnZeroPageCount={null}
                                    />
                              </div>
                        </div>
                      </div>
                  </div>
             </div>
          </div> 
      </>)}
    </>
  );   
};

export default SearchResult;