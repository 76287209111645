import React, { useState } from "react";
import CarouselSlide from "../CarouselSlide/CarouselSlide";


interface LinkItem {
    url: string;
    isExternal: boolean;
    label: string;
    iconUrl: string;    
}

interface BannerJob {
    id: string;
    title:string;
    location: string;
    designation : string;
    jobCount:number;
    jobOpeningId:string;    
}
interface BannerImage{
    url:string;
    alt:string;
    text:string;
    bannerLinks: LinkItem[];
    isVideo:boolean;
    bannerCardClass: string;
    isButton:boolean;
    buttonText:string;
    buttonUrl:string;
    isBannerSearch?:boolean;
    bannerSearchText?:string;
    bannerSearchIcon?:string;
    bannerSearchPlaceHolder?:string;
  
}
interface BannerProps{
    isCarousle:boolean;
    bannerCardPosition: string;
    bannerCardColor: string;
    bannerImages: BannerImage[];    
    stretchFullWidth: boolean;    
    bannerClass: string;
    bannerJobContentFlag:boolean;
    bannerJobContent?:BannerJob;
    bannerTextFlag?:boolean;
    bannerText?:Bannertext;
    handleBannerSearchText:(searchValue:string) => void;
}
interface Bannertext{
    title:string;
    description:string;
}

const Banner = ({
    isCarousle,
    bannerCardPosition,
    bannerCardColor,
    bannerImages,
    stretchFullWidth,
    bannerClass,
    bannerJobContentFlag,
    bannerJobContent,
    bannerTextFlag,
    bannerText,
    handleBannerSearchText
}:BannerProps) =>{
   const [bannerSearchValue,setBannerSearchValue] = useState("");  

   const officeMailID = process.env.REACT_APP_JOB_OFFICIAL_MAILID; 
   const zohoRecruitAPI = process.env.REACT_APP_ZOHO_RECRUIT_URL; 
   const zohoJobTitle = bannerJobContent?.title || "";
   const zohoJobOpeningId = bannerJobContent?.jobOpeningId || "";
   const replaceZohoRecruitAPI = zohoRecruitAPI?.replace('#JOBOPENINGID#',zohoJobOpeningId)
                                .replace("#JOBTITLE#",zohoJobTitle.replaceAll(" ","-"))
                                .replace("#ZOHOFORM#",'$apply=true'); 
    const handleChangeEvent = (e:any) => {
        setBannerSearchValue(e.target.value);                
    };
    const handleKeyPressEvent = (e:any) => {
        if(e.key === 'Enter'){
            if(bannerSearchValue !== "" && bannerSearchValue !== " "){
                handleBannerSearchText(bannerSearchValue);
            }
        }
        if(bannerSearchValue === ""){
            clearSearch();
        }
    }
    const handleSubmitEvent = () => {
        console.log("Search Value",bannerSearchValue);
        if(bannerSearchValue !== "" && bannerSearchValue !== " "){
            handleBannerSearchText(bannerSearchValue);
        }
    };
    const clearSearch = () => {
        handleBannerSearchText(bannerSearchValue);
    };
    return(
    <>    
        {isCarousle ? (            
            <CarouselSlide carouselType={"Banner"}
                tileSlides={[]}
                bannerSlides={bannerImages}
                showDots={false}
                showArrows={false}
                showToSlide={1}/>                       
        )
        : 
        (
        <>
            <div className={`banner-container ${bannerImages[0].bannerCardClass}`}>
                <div className="container liquid-container hidden-xs hidden-sm">
                    {bannerImages[0].text !== "" && bannerJobContentFlag == false && (
                        <div className="bhp-banner-content-wrap">
                            <div className="bhp-banner-contents">
                                <h2 className="bellSlimBold middle banner-text txtBold txtSize32 txtBlack no-margin-top line-height-38">{bannerImages[0].text}</h2>
                                <div className="spacer10"></div>
                                {bannerImages[0].isButton ? (<>
                                    <button className="btn btn-default">{bannerImages[0].buttonText}</button>
                                </>):(<></>)}
                            </div>
                        </div>
                    )}
                    {bannerImages[0].isBannerSearch ? (
                        <div className="txtCenter  bhp-banner-search-wrap">
                            <h2 className="bellSlim middle txtBold txtWhite txtSize32 no-margin-top line-height-38">{bannerImages[0].bannerSearchText}</h2>
                            <div className="bhp-banner-search-contents">
                                <input type="text" value={bannerSearchValue} id="banner-search-txt" onChange={handleChangeEvent} 
                                            placeholder={bannerImages[0].bannerSearchPlaceHolder}
                                            onKeyPress={handleKeyPressEvent}                                             
                                            className="bhp-banner-text-box"/>
                                <div onClick={handleSubmitEvent} className="bhp-banner-text-btn"><i className="bhp-banner-text-btn-icon"></i></div>
                            </div>
                        </div>
                    ):(<></>)}

                    {bannerJobContentFlag == true && (
                                    <div className="bhp-banner-content-wrap">
                                        <div className="bhp-banner-job-contents bhp-banner-contents">
                                            <h2 className="bellSlimBold middle banner-text txtBold txtSize32 no-margin-top line-height-38">{bannerJobContent?.title}</h2>
                                            <div className="spacer20"></div>
                                            <div>
                                                <p>
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="#1C2033" x="246" y="246" role="img" className="icon-Position" xmlns="http://www.w3.org/2000/svg"><g fill="#1C2033"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 15h13.865a1 1 0 0 0 .768-1.64L15 9l3.633-4.36A1 1 0 0 0 17.865 3H4v18" /></g></svg>
                                                    <span className="txtSize16 bellSlimBold">&nbsp;&nbsp;{bannerJobContent?.id}</span>
                                                </p>
                                                <p>
                                                    <svg width="20px" height="20px" viewBox="0 0 512 512" fill="#1C2033" x="246" y="246" role="img" className="icon-Position" xmlns="http://www.w3.org/2000/svg"><g fill="#1C2033"><path fill="currentColor" d="M253.924 127.592a64 64 0 1 0 64 64a64.073 64.073 0 0 0-64-64Zm0 96a32 32 0 1 1 32-32a32.037 32.037 0 0 1-32 32Z" /><path fill="currentColor" d="M376.906 68.515A173.922 173.922 0 0 0 108.2 286.426l120.907 185.613a29.619 29.619 0 0 0 49.635 0l120.911-185.613a173.921 173.921 0 0 0-22.747-217.911Zm-4.065 200.444l-118.916 182.55l-118.917-182.55c-36.4-55.879-28.593-130.659 18.563-177.817a141.92 141.92 0 0 1 200.708 0c47.156 47.158 54.962 121.938 18.562 177.817Z" /></g></svg>
                                                    <span className="txtSize16 bellSlimBold">&nbsp;&nbsp;{bannerJobContent?.location}</span>
                                                </p>
                                                <p>
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="#1C2033" x="246" y="246" role="img" className="icon-Position" xmlns="http://www.w3.org/2000/svg"><g fill="#1C2033"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M22 19V9a2 2 0 0 0-2-2h-6.764a2 2 0 0 1-1.789-1.106l-.894-1.788A2 2 0 0 0 8.763 3H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2Z" /></g></svg>
                                                    <span className="txtSize16 vertical-align-middle bellSlimBold">&nbsp;&nbsp;{bannerJobContent?.designation}</span>
                                                </p>                                                
                                                <p className="txtSize24 bellSlimBold">{bannerJobContent?.jobCount} &nbsp; { bannerJobContent?.jobCount == 1 ? "Opening" : "Openings"}</p>
                                            </div>
                                            <div className="spacer20"></div>

                                            {bannerImages[0].isButton ? (<>
                                                <a href={officeMailID} className="btn btn-banner-white" target="_blank">{bannerImages[0].buttonText}</a>
                                            </>) : (<></>)}
                                        </div>
                                    </div>
                    )}

                    {bannerTextFlag == true && (
                       <div className="">
                            <div className="clear spacer50"></div>
                            <div className="txtCenter">
                                <h2 className="bellSlimBold middle banner-text txtBold txtSize32 no-margin-top line-height-38">{bannerText?.title}</h2>
                                <h4>{bannerText?.description}</h4>
                            </div>
                       </div>
                    )}

                </div>
            </div>            
        </>
        )}
        
         {isCarousle == false ? (  
        <div className="hidden-lg hidden-md">
            <div className="bhp-banner-content-wrap-small-media">
                {bannerImages[0].text !== "" && bannerJobContentFlag == false && (
                    <div className="bhp-banner-contents">
                            <h2 className="bellSlimBold middle banner-text txtBold txtSize32 txtBlack no-margin-top line-height-38">{bannerImages[0].text}</h2>                        
                            {bannerImages[0].isButton ? (<>
                                    <button className="btn btn-default">{bannerImages[0].buttonText}</button>
                            </>):(<></>)}
                    </div>
                )}
                  {bannerJobContentFlag == true && (
                                   
                                        <div className="bhp-banner-contents">
                                            <h2 className="bellSlimBold middle banner-text txtBold txtSize32 no-margin-top line-height-38">{bannerJobContent?.title}</h2>
                                            <div className="spacer20"></div>
                                            <div className="banner-content-xs">
                                                <p>
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="#1C2033" x="246" y="246" role="img" className="icon-Position" xmlns="http://www.w3.org/2000/svg"><g fill="#1C2033"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 15h13.865a1 1 0 0 0 .768-1.64L15 9l3.633-4.36A1 1 0 0 0 17.865 3H4v18" /></g></svg>
                                                    <span className="txtSize16 bellSlimBold">&nbsp;&nbsp;{bannerJobContent?.id}</span>
                                                </p>
                                                <p>
                                                    <svg width="20px" height="20px" viewBox="0 0 512 512" fill="#1C2033" x="246" y="246" role="img" className="icon-Position" xmlns="http://www.w3.org/2000/svg"><g fill="#1C2033"><path fill="currentColor" d="M253.924 127.592a64 64 0 1 0 64 64a64.073 64.073 0 0 0-64-64Zm0 96a32 32 0 1 1 32-32a32.037 32.037 0 0 1-32 32Z" /><path fill="currentColor" d="M376.906 68.515A173.922 173.922 0 0 0 108.2 286.426l120.907 185.613a29.619 29.619 0 0 0 49.635 0l120.911-185.613a173.921 173.921 0 0 0-22.747-217.911Zm-4.065 200.444l-118.916 182.55l-118.917-182.55c-36.4-55.879-28.593-130.659 18.563-177.817a141.92 141.92 0 0 1 200.708 0c47.156 47.158 54.962 121.938 18.562 177.817Z" /></g></svg>
                                                    <span className="txtSize16 bellSlimBold">&nbsp;&nbsp;{bannerJobContent?.location}</span>
                                                </p>
                                                <p>
                                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="#1C2033" x="246" y="246" role="img" className="icon-Position" xmlns="http://www.w3.org/2000/svg"><g fill="#1C2033"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M22 19V9a2 2 0 0 0-2-2h-6.764a2 2 0 0 1-1.789-1.106l-.894-1.788A2 2 0 0 0 8.763 3H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2Z" /></g></svg>
                                                    <span className="txtSize16 vertical-align-middle bellSlimBold">&nbsp;&nbsp;{bannerJobContent?.designation}</span>
                                                </p>
                                                <p className="txtSize24 bellSlimBold">{bannerJobContent?.jobCount} &nbsp; { bannerJobContent?.jobCount == 1 ? "Opening" : "Openings"}</p>
                                            </div>
                                            <div className="spacer20"></div>

                                            {bannerImages[0].isButton ? (<>
                                                <a href={officeMailID} className="btn btn-default" target="_blank">{bannerImages[0].buttonText}</a>
                                            </>) : (<></>)}
                                        </div>
                                 
                    )}
                {bannerImages[0].isBannerSearch ? (<>
                    <div className="txtCenter  bhp-banner-search-wrap">
                        <h2 className="bellSlim middle txtBold txtWhite txtSize32 no-margin-top line-height-38">{bannerImages[0].bannerSearchText}</h2>
                        <div className="bhp-banner-search-contents">
                            <input type="text" value={bannerSearchValue} id="banner-search-txt" 
                            onChange={handleChangeEvent} 
                            placeholder={bannerImages[0].bannerSearchPlaceHolder} 
                            onKeyPress={handleKeyPressEvent} 
                            className="bhp-banner-text-box"/>
                            <div onClick={handleSubmitEvent} className="bhp-banner-text-btn"><i className="bhp-banner-text-btn-icon"></i></div>
                        </div>
                    </div>
                </>):(<></>)}

                {bannerTextFlag == true && (
                       <div className="bhp-banner-contents">
                            <div className="clear spacer10"></div>
                            <div className="txtCenter">
                                <h2 className="bellSlimBold middle banner-text txtBold txtSize32 no-margin-top line-height-38">{bannerText?.title}</h2>
                                <h4>{bannerText?.description}</h4>
                            </div>
                       </div>
                )}
                
            </div>
        </div>
         ):(<></>)}
        
    </>);
};

export default Banner;