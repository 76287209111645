import React, { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import JobSearchData from "../../json/jobsearchpage.json";
import CarouselSlide from "../../components/CarouselSlide/CarouselSlide";
import SearchResult from "../../components/Search/SearchResult";
import MetaTag from "../../components/MetaTag/MetaTag";
import CareerPageData from "../../json/careerpage.json";
import S3ClientService   from "../../service/S3Client";
import JobSpecification from "../../json/jobSpecification.json";

const jobBannerList = JobSearchData.bannerSection;
const jobHighLightList = JobSearchData.highLightSection;

interface KeyValue{
  value:string;
}
interface Job {
  jobId: string;
  jobTitle: string;
  jobDescription: string;
  location: string;
  jobCategory:string;
  jobType:string,
  workProfile:string,
  experience:string,  
  noOfOpenings:number,          
  keyResponsibility:KeyValue[],
  technologySkills:KeyValue[],
  requiredQualification:KeyValue[],
  workingConditions?:KeyValue[],
  ourDetails:string,
  ourOffer:KeyValue[],
  additionalInformation:KeyValue[],
  status:string,
  effectiveFrom:string,
  effectiveTo:string,
  createdOn: string,
  publishedOn: string,
  completedOn: string,
  holdOn: string,
  deletedOn: string
}

const CareerSearch = () => {
    const [jobSpecification,setJobSpecification] = useState<Job[]>([]);    
    const [bannerSearchText, setBannerSearchText] = useState("");
    const [selectedFilters, setSelectedFilters] = useState<{
        category: Set<string>;
        jobType: Set<string>;
    }>({
        category: new Set(),
        jobType: new Set(),
    });
    useEffect(() => {      
      window.scrollTo({
        top: 0,
        behavior: 'auto'
      });
                  
      // S3ClientService().send((res,data) => {
      //   const jsonString = data.Body?.toString('utf-8');
      //   const jsonObject = JSON.parse(jsonString || '{}');  
      //   setJobSpecification(jsonObject.jobs.filter((job:any) => job.status === "Published"));
      // });
      setJobSpecification(JobSpecification.jobs.filter((job:any) => job.status === "Published"));
    },[]);            
    
    const handleFiltersChange = (filters: { category: Set<string>; jobType: Set<string> }) => {
       setSelectedFilters(filters);
    };
      
    const handleBannerSearchText = (searchValue:any) =>{        
      setBannerSearchText(searchValue);      
    };
    return(
    <>
        <MetaTag title={ CareerPageData.title } description={ CareerPageData.description }/>
        
        <Banner 
            isCarousle={false}
            bannerCardPosition={"center"}
            bannerCardColor={""}
            bannerImages={jobBannerList}
            stretchFullWidth={true}
            bannerClass={""}
            bannerJobContentFlag={false}
            handleBannerSearchText={handleBannerSearchText}/>
        <div className="container liquid-container pad-30">           
            <div>
              <h2 className="bellSlimBold txtCenter txtBlack txtBold txtSize32 letterSpacing
                      txtSize26-xs margin-15-top">{jobHighLightList.title}</h2>
              <div className="spacer30"></div>
            </div>
            <div className="hidden-sm hidden-xs">
              <CarouselSlide carouselType={"Tile"}
                  tileSlides={jobHighLightList.highLightJobs}
                  bannerSlides={[]}
                  showDots={false}
                  showArrows={false}
                  showToSlide={4}/>
            </div>
            <div className="hidden-lg hidden-md">
              <CarouselSlide carouselType={"Tile"}
                  tileSlides={jobHighLightList.highLightJobs}
                  bannerSlides={[]}
                  showDots={true}
                  showArrows={false}
                  showToSlide={3}/>
            </div>
        </div>
        <div className="clear spacer40 hidden-xs"></div>
        <SearchResult onFiltersChange={handleFiltersChange} bannerSearchText={bannerSearchText} jobSpecifications={jobSpecification} />         
    </>);
};

export default CareerSearch;