import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Banner from "../../components/Banner/Banner";
import AsideBox from "../../components/AsideBox/AsideBox";
import CareerDetailsData from "../../json/careerdetailspage.json";
import JobSearchData from "../../json/jobSpecification.json";
import MetaTag from "../../components/MetaTag/MetaTag";
import CareerPageData from "../../json/careerpage.json";
import S3ClientService from "../../service/S3Client";
import ZohoJobData from "../../json/zoho.json";

interface KeyValue{
    key?:string;
    value:string;
  }
  interface Job {
    jobId: string;
    jobTitle: string;
    jobDescription: string;
    location: string;
    jobCategory:string;
    jobType:string,
    workProfile:string,
    experience:string,  
    noOfOpenings:number,          
    keyResponsibility:KeyValue[],
    technologySkills:KeyValue[],
    requiredQualification:KeyValue[],
    workingConditions?:KeyValue[],
    ourDetails:string,
    ourOffer:KeyValue[],
    additionalInformation:KeyValue[],
    status:string,
    effectiveFrom:string,
    effectiveTo:string,
    createdOn: string,
    publishedOn: string,
    completedOn: string,
    holdOn: string,
    deletedOn: string,
    jobOpeningId?:string
  }

const careerDetailsBanner = CareerDetailsData.bannerSection;
const CareerDetail = () => {
    const [jobSpecification,setJobSpecification] = useState<Job[]>([]); 
    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        });

        S3ClientService().send((res,data) => {
            const jsonString = data.Body?.toString('utf-8');
            const jsonObject = JSON.parse(jsonString || '{}');  
            setJobSpecification(jsonObject.jobs.filter((job:any) => job.status == "Published"));
            //console.log(jsonObject.jobs);
          });
        //setJobSpecification(JobSearchData.jobs.filter((job:any) => job.status === "Published"));
      },[])
    const { jobId } = useParams();
    const officeMailID=process.env.REACT_APP_JOB_OFFICIAL_MAILID;     
    const zohoRecruitAPI = process.env.REACT_APP_ZOHO_RECRUIT_URL; 
    const jobSearchResult = jobSpecification?.filter((job) => job.jobId.includes(jobId || ''));
    const similerJobSearchResult = jobSpecification?.filter(item => item.jobId !== jobId);
    const replaceZohoRecruitAPI = zohoRecruitAPI?.replace('#JOBOPENINGID#',jobSearchResult[0]?.jobOpeningId ? jobSearchResult[0]?.jobOpeningId : "")
                                    .replace("#JOBTITLE#",jobSearchResult[0]?.jobTitle.replaceAll(" ","-"))
                                    .replace("#ZOHOFORM#",'$apply=true');
    const jobBannerText =
    {
        "id": jobSearchResult[0]?.jobId,
        "title": jobSearchResult[0]?.jobTitle,
        "location": jobSearchResult[0]?.location,
        "designation": jobSearchResult[0]?.workProfile,
        "jobCount":jobSearchResult[0]?.noOfOpenings,
        "jobOpeningId":jobSearchResult[0]?.jobOpeningId
    };
    const handleBannerSearchText = (searchValue:any) =>{
        console.log("searchValue",searchValue);
      };
    return (
    <>
        <MetaTag title={ CareerPageData.title } description={ CareerPageData.description } jobTitle={jobSearchResult[0]?.jobTitle}/>
            
            <Banner
                isCarousle={false}
                bannerCardPosition={"center"}
                bannerCardColor={""}
                bannerImages={careerDetailsBanner}
                stretchFullWidth={true}
                bannerClass={""}
                bannerJobContentFlag={true}
                bannerJobContent={jobBannerText}
                handleBannerSearchText={handleBannerSearchText}
            />
      {jobSearchResult.length > 0 ? (
        <>            
            <div className="bgWhite pad-15-xs">
                <div className="container liquid-container">
                    <div className="spacer30"></div>
                    <div className="inline-blck w-100">

                        <div className="right-blck w-70 w-sm-60  pad-right-20 pad-right-20-md pad-15-right-sm w-100-xs pad-top-20-xs">
                            <div className="zoho-content" dangerouslySetInnerHTML={{ __html: jobSearchResult[0].jobDescription.replace("<h3>Requirements</h3>","").replace("<h3>Benefits</h3>","") }}></div>
                        </div>

                        <div className="left-blck w-30 w-sm-40  w-100-xs">
                            <AsideBox
                                title="Similar Jobs"
                                description=""
                                subTitle=""
                                fullLine={true}
                                asideBoxButtonClass="career-banner-search-box"
                                asideBoxButton={false}
                                asideBoxButtonText="Submit"
                                asideBoxInputClass="career-banner-search-input"
                                asideBoxInputPlaceHolder=""
                                asideBoxEmail={false}
                                customBodyClass={"bgGray19 contentBox-description-sm"}
                                asideList={true}
                                asideListDetails={similerJobSearchResult}
                                asideSocialIcons={false}
                                asideSocialTitle=""
                                asideBoxItemShowLength={3}
                                asideBoxFolderIcon={true}
                                asideBoxLocationIcon={false}
                            />

                            <AsideBox
                                title="Share this opportunity"
                                description=""
                                subTitle=""
                                fullLine={false}
                                asideBoxButtonClass="career-banner-search-box"
                                asideBoxButton={false}
                                asideBoxButtonText="Submit"
                                asideBoxInputClass="career-banner-search-input"
                                asideBoxInputPlaceHolder=""
                                asideBoxEmail={false}
                                customBodyClass={"bgGray19 mar-top-15  pad-30  contentBox-description-sm"}
                                asideList={false}
                                asideListDetails={[]}
                                asideSocialIcons={true}
                                asideSocialTitle={jobSearchResult[0].jobTitle}
                                asideBoxItemShowLength={2}
                                asideBoxFolderIcon={false}
                                asideBoxLocationIcon={false}
                            />

                        </div>

                        {/* <AsideBox 
                                title="Get notified for similar jobs"
                                description="Sign up to receive job alerts"  
                                subTitle="Email*"  
                                fullLine={false}
                                asideBoxButtonClass="career-banner-search-box"
                                asideBoxButton={true}
                                asideBoxButtonText="Submit"
                                asideBoxInputClass="career-banner-search-input"
                                asideBoxInputPlaceHolder=""
                                asideBoxEmail={false}
                                customBodyClass={"bgGray19 right-blck w-30 w-sm-40 pad-30  w-100-xs contentBox-description-sm"}
                                asideList={false}    
                                asideListDetails={[]}       
                                asideSocialIcons={false}                                                                            
                                /> */}


                        {/* <AsideBox 

                                title="Get tailored job recommendations based on your interests."
                                description=""  
                                subTitle=""  
                                fullLine={false}
                                asideBoxButtonClass="career-details-box"
                                asideBoxButton={false}
                                asideBoxButtonText="Get Started"
                                asideBoxInputClass=""
                                asideBoxInputPlaceHolder=""
                                asideBoxEmail={true}
                                customBodyClass={"bgGray19 mar-top-15 left-blck w-30 w-sm-40 pad-30  w-100-xs contentBox-description-sm"}
                                asideList={false}    
                                asideListDetails={[]}           
                                asideSocialIcons={false}                                       
                                />     */}






                        {/* <AsideBox
                                        title="Jobseekers Also Viewed"
                                        description=""
                                        subTitle=""
                                        fullLine={true}
                                        asideBoxButtonClass="career-banner-search-box"
                                        asideBoxButton={false}
                                        asideBoxButtonText="Submit"
                                        asideBoxInputClass="career-banner-search-input"
                                        asideBoxInputPlaceHolder=""
                                        asideBoxEmail={false}
                                        customBodyClass={"bgGray19 mar-top-15 left-blck w-30 w-sm-40 pad-40  w-100-xs contentBox-description-sm"}
                                        asideList={true}
                                        asideListDetails={jobSeekerJobList}
                                        asideSocialIcons={false}
                                        asideBoxItemShowLength={2}
                                        asideBoxFolderIcon={true}
                                        asideBoxLocationIcon={true}
                                    /> */}


                    </div>

                    <div className="spacer20"></div>
                    <hr className="footer-line"></hr>
                    <div className="spacer30"></div>
                    <div className="txtCenter-xs">
                        <a href={replaceZohoRecruitAPI} className="btn btn-default" target="_blank">{careerDetailsBanner[0].buttonText}</a>

                    </div>


                </div>
            </div>
        </>
        ):
        (
            <>
                <div id="brf-page-loader" className="loader-fixed bgWhite container-flex-box brf-page-loader">
                    <svg className="loading-indicator-circle middle-align-self" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><defs>
                        <linearGradient id="loadingIndicatorGradient1" x1="0" x2="0" y1="10%" y2="90%">
                        <stop offset="0" stop-color="#04569b"></stop><stop offset="1" stop-color="#97b6d2"></stop>
                        </linearGradient>
                        <linearGradient id="loadingIndicatorGradient2" x1="0" x2="0" y1="90%" y2="10%"><stop offset="0" stop-color="#97b6d2"></stop><stop offset="1" stop-color="#fff"></stop></linearGradient></defs>
                        <path fill="url(#loadingIndicatorGradient1)" d="M24 3C12 3 3 12 3 24S12 45 24 45v-4c-10 0-17-8-17-17S15 7 24 7v-4z"></path><path fill="url(#loadingIndicatorGradient2)" d="M24 3v4c10 0 17 8 17 17S34 41 24 41v4c12 0 21-10 21-21S36 3 24 3z"></path>
                    </svg>
                    <div className="page-loader-msg middle-align-self">Loading data, please wait</div>
                </div>
            </>
        )}
    </>);
};

export default CareerDetail;