import React,{useEffect,useState}  from "react";
import AWS from "aws-sdk";


AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    region: process.env.REACT_APP_AWS_REGION
});
const S3ClientService = () => {  
    const s3 = new AWS.S3();
    const params = {
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET || '',
      Key: process.env.REACT_APP_AWS_S3_BUCKET_OBJECT || '',
    };
    
    let jobPostings = s3.getObject(params);

    return jobPostings;
};

export  default S3ClientService;