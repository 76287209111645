import React, { useEffect } from "react";
import { useParams ,NavLink } from "react-router-dom";
import SubFooter from "../../components/SubFooter/SubFooter";
import BlogDetailsData from "../../json/blogPage.json";
import MetaTag from "../../components/MetaTag/MetaTag";



const blogDataJson = BlogDetailsData;

const BlogDetails =() => {

    const { name } = useParams();
    const blogDetails = blogDataJson.blogs.filter((details) => details.name.includes(name || ''));    
    const blogList = blogDataJson.blogs.filter(item => item.name !== name);
      useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: 'auto'
        });
      }, [])

    return(
        <>
         {/* <MetaTag title={ ResourcePageData.title } description={ ResourcePageData.description }/> */}
         <MetaTag title={ "Blog Detail" } description={ "details of the blog " }/>
         
       
        <div className="bgWhite pad-15-xs">
        <div className="liquid-container container">
        <div className="spacer20" aria-hidden="true"></div>
        <div className="back_button cursor_pointer">
            <NavLink role="button" tabIndex={-1} to="/blog-list">
              <span >
                <img className="back_button_image pb-1" src="/content/img/big-back-arrow.svg" />
                <span className="pad-left-10">Back</span>
              </span>
            </NavLink>
                
        </div>
        <div>

              <div className="padLR-10 col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8  blog-left-pane">


                <h2 className="bellSlimBold  txtBlack txtSize32 letterSpacing txtLeft
					          txtSize26-xs margin-15-top">{blogDetails[0].title}</h2>
                <h2 className="txtSize12 txtBlack letterSpacing txtLeft txtBlack
					          txtuppercase margin-15-bottom">{blogList[0].subTitle}</h2>

                <p className="pad-30-right pad-30-bottom">
                  <img src={blogDetails[0].url_description} className="w-100" alt={blogDetails[0].title} />

                </p>

                <div className="txtSize16" dangerouslySetInnerHTML={{ __html: blogDetails[0].long_description }}></div>
                <div className="hidden-lg hidden-md">
                  <hr></hr>
                </div>
              </div>

            <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <h2 className="txtSize20 padLR-30 txtBlack txtBold">You may also like...</h2>
              <div>
                <div className={`blog-content pad-30`}>
                  {blogList.map((list: any, index: number) => {
                    return (
                      <div>
                        <div className="row">

                          <div className="padLR-10 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6  txtCenter">
                            <p>
                              <img className="blog-list-width" src={list.url_description} />


                            </p>
                          </div>
                          <div className= "padLR-10 col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6 txtCenter">
                            <p className="txtLeft txtBlack txtBold txtSize14">

                              {list.title}
                            </p>
                            <p className="txtLeft">
                              <a href={list.name} className="icon more-link txtBold">Learn More</a>
                            </p>


                          </div>
                        </div>
                        <div>
                          <hr />

                        </div>
                      </div>
                    );
                  })}
                </div>


              </div>


            </div>

        </div>

        </div>    
        </div>
        <SubFooter
          title="Need more information?"
          buttonText="Contact us"
          buttonUrl="/contactus"
          isCustomInfo={true}
          moreInformationText="Connect with us today to learn more about our services and solutions"
          moreInformationMailToId="Email: info@bceglobaltech.com"
          />



        
        </>

    );

};
export default BlogDetails